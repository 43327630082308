<script lang="ts" setup>
import { computed } from "vue";
import prism, { highlight, languages } from "prismjs";
import "prismjs/components/prism-java";
import "prismjs/components/prism-json";
import "prismjs/components/prism-typescript";
import "prismjs/components/prism-markup";
import "prismjs/components/prism-markdown";
import "prismjs/components/prism-csharp";
import "prismjs/components/prism-rust";
import "prismjs/components/prism-kotlin";
import "prismjs/components/prism-scala";
import "prismjs/components/prism-rest";
import "prismjs/components/prism-http";
import "prismjs/components/prism-uri";
import "prismjs/components/prism-bash";
import "prismjs/components/prism-yaml";

prism.manual = true;

const props = defineProps<{ code: string; language: string; title?: string }>();

const plaintextGrammar = languages.plaintext;

const html = computed(() => {
  const grammar = languages[props.language] ?? plaintextGrammar;
  return highlight(props.code, grammar, props.language);
});
</script>

<template>
  <div v-if="title" class="prismcode-wrapper">
    <div class="language-indicator">{{ title }}</div>
    <pre :class="`language-${language}`" class="pl_radius25"><code v-html="html"></code></pre>
  </div>
  <pre v-else :class="`language-${language}`" class="pl_radius25"><code v-html="html"></code></pre>
</template>

<style lang="scss" scoped>
@use "@otto-ec/global-frontend-settings/gfs";

.prismcode-wrapper {
  position: relative;

  > pre {
    padding-top: 24px;
  }

  .language-indicator {
    background-color: gfs.$pl_color-grey200;
    border-bottom-right-radius: 4px;
    color: gfs.$pl_color-white100;
    display: flex;
    align-items: center;
    font-size: 11px;
    height: 18px;
    left: 0;
    padding: 2px 8px;
    position: absolute;
    top: 0;
  }
}

pre {
  white-space: pre;
}
</style>
