<script lang="ts" setup>
import { computed } from "vue";
import { marked, Renderer } from "marked";

const props = defineProps<{ markdown: string; deprecated?: boolean }>();

const renderer = new Renderer();

renderer.paragraph = (token) => {
  const text = marked.Parser.parseInline(token.tokens);
  return `<p class="pl_copy100">${text}</p>`;
};

renderer.link = (token) => {
  const text = marked.Parser.parseInline(token.tokens);
  const href = token.href;
  return `<a class="pl_link--primary" href="${href}">${text}</a>`;
};

renderer.list = (token) => {
  const body = token.items.map((item) => marked.Parser.parseInline(item.tokens)).join("");
  return token.ordered
    ? `<ol class="pl_list--ordered">${body}</ol>`
    : `<ul class="pl_list--unordered">${body}</ul>`;
};

renderer.codespan = (token) => {
  const code = marked.Parser.parseInline(token.tokens);
  return `<code>${code}</code>`;
};

// Neuer Renderer für HTML
renderer.html = (token) => {
  const html = token.text;
  if (html.startsWith("<object")) {
    return html;
  }
  return `<div>${html}</div>`;
};

const html = computed(() => {
  const renderedHtml = props.markdown
    ? marked(props.markdown, { renderer, headerIds: false, mangle: false })
    : undefined;
  return renderedHtml;
});
</script>

<template>
  <div class="markdown" :class="{ 'markdown--deprecated': deprecated }" v-html="html"></div>
</template>

<style lang="scss" scoped>
.markdown {
  &--deprecated {
    opacity: 0.45;
  }
}
</style>
